import { z } from "zod";

import { CartAddMetadataValidator } from "../../../cart/http/dto/Cart.dto";
import { AwardValidator } from "../../../productpage/http/dto/Awards.dto";
import { undefinable } from "../../utils/zodHelpers";
import { CloudinaryFileValidator } from "./Cloudinary.dto";
import { EnergyLabelValidator } from "./EnergyLabel.dto";
import { ResourceValidator } from "./Resource.dto";

export const ProductLabelTypeValidator = z.enum(["bundle", "showrooms", "description-promo"]);

export const ProductBoxDataValidator = z.object({
  productid: z.number().int().positive(),
  aonumber: z.string(),
  name: z.string(),
  name_verbatim: undefinable(z.string()),
  price: undefinable(z.coerce.number()),
  normal_price: undefinable(z.coerce.number()),
  not_for_sale: z.boolean(),
  in_stock: z.boolean(),
  rating_count: z.coerce.number().default(0),
  rating: undefinable(z.coerce.number()),
  delivery_time: undefinable(z.string()),
  vendornumber: z.string(),
  category: undefinable(z.string()),
  climate_friendly: undefinable(z.boolean()),
  analytics_category: undefinable(z.string()),
  autocreated: undefinable(z.boolean()),
  manufacturer: undefinable(z.string()),
  stock_amount: undefinable(z.coerce.number()),
  absolute_url: z.string(),
  variant_name: undefinable(z.string()),
  variants: undefinable(
    z.object({
      count: z.number().int(),
      in_stock: undefinable(z.union([z.literal("all"), z.literal("some"), z.literal("none")])),
    }),
  ),
  label: undefinable(
    z.object({
      class: undefinable(z.string()),
      type: undefinable(ProductLabelTypeValidator),
      text: z.string(),
    }),
  ),
  primary_resource: undefinable(ResourceValidator),
  hover_resource: undefinable(CloudinaryFileValidator),
  energy_labels: undefinable(EnergyLabelValidator),
  award: undefinable(AwardValidator),
  offer: undefinable(
    z.object({
      discountpct: z.coerce.number(),
      discount: z.coerce.number(),
    }),
  ),
  raptor_analytics: undefinable(
    z.object({
      pid: z.string(),
      method: z.string(),
    }),
  ),
  specifications: undefinable(z.record(z.string(), z.string())),
  shipping_fee: undefinable(z.coerce.number()),
  boost_text: undefinable(z.string()),
  cart: CartAddMetadataValidator,
});

export type IProductBoxData = z.infer<typeof ProductBoxDataValidator>;
export type IProductLabelType = z.infer<typeof ProductLabelTypeValidator>;
